// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

import {IProductEnvironment} from "./FacilitiesEvaluationEnvironment";
import {FacilityAccessibilityProduct} from "../product.facility/model/FacilityAccessibilityProduct";
import {EProductType} from "../javascript.lib.mojo-base/model/ProductType";
import {FacilityAccessibilityProduct2} from "../product.facility/model/FacilityAccessibilityProduct2";
import {FacilityAccessibilityProduct4} from "../product.facility/model/FacilityAccessibilityProduct4";

export const environment: IProductEnvironment = {

  name: 'Sodexo: Facilities',
  filename: 'environment.test-momojo-sodexo.ts',

  isDevelopment: false,
  isProduction: true,
  isTest: false,

  authenticationConfig: {
    emailLink: false,
    textMessage: true,
    usernamePassword: true,
    google: false
  },

  firebaseConfig: {
    apiKey: "AIzaSyA8juDmYt55NhgEQyl5mAmxaK3in6UBBNo",
    appId: "1:692325989926:web:43500712465aa6a924b0e7",
    authDomain: "test-momojo-sodexo.firebaseapp.com",
    databaseURL: "https://test-momojo-sodexo-default-rtdb.firebaseio.com",
    messagingSenderId: "692325989926",
    projectId: "test-momojo-sodexo",
    storageBucket: "test-momojo-sodexo.appspot.com",
  },

  // curl -iLS https://us-central1-$_TARGET_ENV.cloudfunctions.net/public/functions/info
  cloudFunctionsUrl: 'https://us-central1-test-momojo-sodexo.cloudfunctions.net',

  nocoDbConfig: {
    // httpServer: 'http://localhost:8080',
    httpServer: 'https://mobilitymojo-spot.com',
    user: 'test@mobilitymojo.com',
    password: 'test@mobilitymojo.com',
    authorization: 'Basic bW9iaWxpdHk6bW9qbw==',
    projectTitle: '2024 - Grosvenor and The Crown Estate',
  },

  productConfig: {
    firebaseAnswersRoot: '/answers-x/facilities',
    product:  FacilityAccessibilityProduct4.INSTANCE,
    productType: EProductType.facilities,
    canCommentOnClusters: true,
    canEditClusters: false,
    clusterSupport: true,
  },
};
